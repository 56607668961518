import React from "react";
import resumeFile from "../documents/resume-gv.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Gagan Vaid,</span> a Full Stack Web
              Developer
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              I'm Gagan Vaid, a dedicated Full Stack Web Developer specializing in creating impactful digital solutions. My experience in web development includes leading a significant project for the <span className="text-primary"><b>Election Commission of India</b></span>, developed using Laravel, HTML, CSS, and jQuery.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            <span className="text-primary"><b>Red Cross Netherlands</b></span> presented another challenging project where, as a senior developer, I contributed to the successful development of a React.js and Symfony 4-based portal, effectively managing over 500,000 users. This project served as a testament to our team's top-notch coding quality and expertise.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Additionally, I'm the creator behind <span className="text-primary"><b>'Art of Coding,' my YouTube channel</b></span> dedicated to sharing insights into web development and back-end frameworks like Symfony.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Proficient in ReactJS, Redux, Vue.js, PHP, HTML, CSS, and frameworks such as Symfony and Laravel, I consistently adhere to the highest coding standards, ensuring the delivery of superior solutions within specified timelines.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Gagan Vaid
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="gaganvaid4641@gmail.com">gaganvaid4641@gmail.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>31
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Ontario,
                  Canada
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>8</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>23</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>15</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>100%</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Success Rate
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
