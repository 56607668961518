import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details Design
  const services = [
    {
      name: "Website Development & Design",
      desc: "Full Stack Developer proficient in both website development and design, crafting seamless digital experiences from coding to visual elements.",
      icon: "fas fa-laptop-code",
    },
    {
      name: "APIs Integration",
      desc: "Expert in seamless APIs integration, connecting systems for efficient functionality.",
      icon: "fas fa-network-wired",
    },
    {
      name: "Single Page Applications",
      desc: "Specialist crafting dynamic Single Page Applications for streamlined user experiences, leveraging cutting-edge frontend technologies like React.js",
      icon: "fas fa-react",
    },
    {
      name: "Database Management",
      desc: "Proficient in robust database management, ensuring efficient data handling and optimization.",
      icon: "fas fa-database",
    },
    {
      name: "Server Management",
      desc: "Skilled in efficient server management, ensuring reliable and optimized server performance.",
      icon: "fas fa-server",
    },
    {
      name: "Content Creator",
      desc: "Skilled content creator simplifying complex coding concepts for diverse YouTube audiences.",
      icon: "fas fa-lightbulb",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
