import React from "react";
import resumeFile from "../documents/resume-gv.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2022 - 2023",
      title: "Post Graduation",
      place: "Conestoga College, Ontario, Canada",
      desc: "◉ Post Graduate Diploma with Distinctions in Applied Network Infrastructure & System Administration. ◉ Coursework: Completed coursework encompassing VMware, Windows Servers, Linux Server, Packet Tracer, Wireshark, SQL, IT infrastructure planning, Operating systems, networks, and virtualization. ◉ Collaborated on diverse projects, tailoring solutions to specific scenarios and requirements provided by the college",
    },
    {
      yearRange: "2010 - 2013", 
      title: "Bachelor Degree",
      place: "Punjab Technical University, Punjab, India",
      desc: "◉ B.Tech with Distinctions in Electronics & Communication Engineering. ◉ Coursework: Covered coursework in a range of subjects including OOPs, Computer Networks, VLSI Design, Digital & Analog Electronics, Communication Methods & Signals, Microprocessors, Microcontrollers, and Optical Fiber Communication ◉ Trainings: Received specialized trainings from HP (Embedded System & robotics) and IATC | Authorized Training Center North-SIEMENS",
    },
    {
      yearRange: "2009 - 2012",
      title: "Diploma",
      place: "Punjab State Board of Technical Education and Industrial Training, Channdigarh, India",
      desc: "Three year Diploma in Electronics & Communication Engineering. ◉ Coursework: Completed coursework covering Basics of IT, Computer Programming, PLC Programming, Microprocessors, Microcontrollers, Troubleshooting, Basic Electrical & Electronics, and EDC & Communication Methods. ◉ Training: Hotel Majestic Park Plaza - Maintenance department (Received the Best Trainee Award)",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2018 - Present",
      title: "Full Stack Developer",
      place: "Smartshore Ability, Netherlands",
      desc: "◉ Led extensive Full Stack and Backend development for web applications such as Red Cross Netherlands, using ReactJS and Symfony. ◉ Renovated a significant fleet management system, resulting in a 40% efficiency boost. ◉ Implemented Docker, collaborated on data strategies, GIT version control, Quality control tools like Cypress for automated tests, Scrutinizer, and client meetings to ensure project success.",
    },
    {
      yearRange: "2014 - 2016",
      title: "Backend Developer",
      place: "01 Synergy, India",
      desc: "◉ Built backend portals and APIs for the Election Commission of India using Laravel, jQuery, HTML, and CSS, achieving a 100% success rate. ◉ Created an AJAX-based Chatbot in Laravel for automated interaction and project cost estimation. ◉ Contributed to various projects in Laravel, CodeIgniter, Zend, CakePHP, WordPress, and Core PHP. ◉ Developed APIs for many vast Government as well as private android and IOS mobile applications",
    },
    {
      yearRange: "2017 - 2019",
      title: "Content Creator",
      place: "Art Of Coding - Youtube",
      desc: "◉ Produced a series of comprehensive videos focused on back-end frameworks like Symfony, featuring practical coding examples and in-depth coverage of key concepts. ◉ Crafted engaging and informative thumbnails, resulting in a remarkable 50% increase in video impressions within a span of 3 months. ◉ Engineered an efficient workflow tailored to streamline video production and enhance workflow management",
    },
  ];

  const skills = [
    {
      name: "React.js",
      percent: 92,
    },
    {
      name: "Redux",
      percent: 91,
    },
    {
      name: "Node.js",
      percent: 95,
    },
    {
      name: "Express.js",
      percent: 93,
    },
    {
      name: "MongoDB",
      percent: 91,
    },
    {
      name: "Laravel",
      percent: 94,
    },
    {
      name: "Symfony",
      percent: 95,
    },
    {
      name: "jQuery & JavaScript",
      percent: 96,
    },
    {
      name: "SQL & PostgreSQL",
      percent: 92,
    },
    {
      name: "Cypress test cases & Scrutinizer",
      percent: 90,
    },
    {
      name: "Git, Github & Bitbucket",
      percent: 90,
    },
    {
      name: "Linux, Windows, LAMP, WAMP, MAMP etc..",
      percent: 91,
    }
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
