import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    // DESIGN: "Desing",
    // BRAND: "Brand",
    // PHOTOS: "Photos",
  };

  const projectsData = [
    {
      title: "Election Commission of India",
      projectInfo:
        "◉ Huge back-end portal built in Laravel, HTML, CSS and jQuery with multiple roles like ECI, CEO, DEO, RO, BLO etc. ◉ Skillfully implemented diverse functionalities for accurate and smooth election operations. ◉ Worked to create Web APIs for multiple android and IOS applications such as RO net, ECI etc.",
      client: "Election Commission of India",
      technologies: "Laravel, HTML5, CSS3, PHP, jQuery, MySql",
      industry: "Election Commission",
      date: "2017 - 2018",
      url: {
        name: "The URL cannot be shared publicly",
        link: "",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/eci.png",
      sliderImages: [
        "images/projects/eci.png",
        "images/projects/eci.png",
      ],
      categories: [filters.BRAND],
    },
    {
      title: "Red Cross (Netherlands)",
      projectInfo:
        "◉ Created a back-end portal in Symfony 4, Magento, HTML, CSS to manage nearly 500,000+ users of various roles, their training, courses, course material, attendance, results, certification and all other responsibilities; achieved 100% success rate. ◉ Successfully implemented Webpack, Datatables, Data-fixtures, Multilingual, Impersonate user, Cypress test cases E-learning, ACL, Version control, Software Development Life Cycle (SDLC), Cron jobs and permission management modules. ◉ Connected the portal with a Magento web shop using Single Sign-on (SSO) and synchronized the data from both sides",
      client: "Red Cross (Netherlands)",
      technologies: "ReactJS, Redux, Symfony 4, SCSS, PHP, jQuery, MySql",
      industry: "Red Cross",
      date: "2018 - 2020",
      url: {
        name: "heartbeat.rodekruis.nl",
        link: "https://heartbeat.rodekruis.nl/login",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/rc.png",
      sliderImages: [
        "images/projects/rc.png",
        "images/projects/rc.png",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Hubble - Fleet Management System",
      projectInfo:
        "◉ Worked with a team of 10 senior developers and designers on a huge fleet management system developed in 8+ years using Symfony 2. ◉ Successfully created modules like Vehicle renting, Payments, Inventory Management, Billing and Invoicing, User Access Control, Security, Performance Analytics etc..",
      client: "Erik van Harmelen",
      technologies: "Symfony 2, HTML5, CSS3, PHP, javascript, jQuery, MySql",
      industry: "Fleet Management System",
      date: "2012 - 2020",
      url: {
        name: "wpb.hubblefleet.nl",
        link: "https://wpb.hubblefleet.nl/login",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/hubble.jpeg",
      sliderImages: [
        "images/projects/hubble.jpeg",
        "images/projects/hubble.jpeg",
      ],
      categories: [filters.PHOTOS],
    },
    {
      title: "Hubble Upgrade",
      projectInfo:
        "◉ As a Team Lead Upgraded this Hubble fleet management system from Symfony 2 to ReactJS, Redux and Symfony 5 in 1.5 years with a team of 6 developers. ◉ Worked on data migrations, upgraded code syntax and bundles, added advanced tools like webpack, cypress, docker etc.",
      client: "Erik van Harmelen",
      technologies: "ReactJS, Redux, Symfony 5, SCSS, jQuery, MySql",
      industry: "Fleet Management System",
      date: "2020 to Present",
      url: {
        name: "Under Development",
        link: "",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/hubble.jpeg",
      sliderImages: [
        "images/projects/hubble.jpeg",
        "images/projects/hubble.jpeg",
      ],
      categories: [filters.BRAND, filters.PHOTOS],
    },
    {
      title: "Canada Prep",
      projectInfo:
        "◉ Canada Prep is a web application designed to streamline the process of applying for a study visa from India to Canada. ◉ It simplifies the journey by generating a personalized checklist of required documents, ensuring a comprehensive and organized approach to the visa application process. ◉ This tool aims to assist applicants in efficiently preparing the necessary paperwork for their study visa application to Canada",
      client: "Gursimran Dhiman",
      technologies: "ReactJS, Redux, Laravel 10, MySql, jQuery",
      industry: "Immigration Services",
      date: "2022 to Present",
      url: {
        name: "Under Development",
        link: "",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/cp.png",
      sliderImages: [
        "images/projects/cp.png",
        "images/projects/cp.png",
      ],
      categories: [filters.DESIGN],
    },
    // {
    //   title: "Project Title 6",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-6.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.BRAND],
    // },
    // {
    //   title: "Project Title 7",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "July 16, 2019",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-7.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.DESIGN, filters.PHOTOS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            {/* <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li> */}
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <button
                            className="popup-ajax stretched-link"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                          </button>
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
